<template>
    <template v-if="this.final">
        <div class="container final-container">
            <h2>Döntő</h2>
            <div class="blue-line"></div>
        </div>
        <div class="container table-responsive  final-table">
            <table class="table table-borderless">
                <thead>
                   <tr>
                        <th>#</th>
                        <th>Csapatnév</th>
                        <th>Iskola</th>
                        <th>Szimuláció</th>
                        <th>Prezentáció</th>
                        <th>Összpontszám</th>
                    </tr>
                </thead>
                <tbody>
                     <tr v-for="result in final" :key="result.teamName">
                        <td>{{result.rank}}</td>
                        <td>{{result.teamName}}</td>
                        <td>{{result.university}}</td>
                        <td>{{result.score1}}</td>
                        <td>{{result.score2}}</td>
                        <td>{{result.sumScore}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </template>
    <div class="container qualifying">
         <h2>Selejtező</h2>
    </div>
    <div class="container table-responsive qualifying-table">
        <table class="table table-borderless" v-if="this.qualifier && this.qualifier.length > 0">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Csapatnév</th>
                    <th>Iskola</th>
                    <th>Piackód</th>
                    <th>Eredmény</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="result in qualifier" :key="result.teamName">
                    <td>{{result.rank}}</td>
                    <td>{{result.teamName}}</td>
                    <td>{{result.university}}</td>
                    <td>{{result.poolCode}}</td>
                    <td>{{result.score}}</td>
                </tr>
            </tbody>
        </table>
        <p v-else class="no-results">Még nincsenek eredmények.</p>
    </div>
</template>

<script>
export default {
  name: 'Results',
  props:['globalData','pageId'],
  components: {
   
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description

    this.fetchData()
    
    return{
        leagues:null,
        qualifier:null,
        final:null
    }
  },
  watch:{
    language(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  },
    methods:{
      fetchData() {
      this.globalData.loader.status = true;
      this.$axios.get('/api/v1/results')
          .then((response) => {
              console.log(response)
            this.resultsData = response.data.data
            this.qualifier = this.resultsData.qualifier
            this.final = this.resultsData.final
              
          })
          .catch(error => {
            console.log(error.message)
            console.error('There was an error!', error)
          })
          .then(() => {
            this.globalData.loader.status = false;
          });
    }
  }
}
</script>
<style scoped>
.qualifying h2, .final-container h2{
    font-size: 1.714rem;
    line-height: 3.428rem;
    font-weight: bold;
}
.final-container h2{
    color:#B7C72A;
}
.final-table{
    margin-bottom: 5.58rem;
}
.table{
    color:#4C5359;
}
thead{
    border-bottom: 1px solid #1C9FD4;
    padding-bottom: .571rem;
}
.final-table thead{
    border-bottom: 1px solid #B7C72A;
}
thead th{
    font-weight: 600;
}
td{
    padding:15px 12px 19px 12px;
    border-top: 1px solid #52ACC4;
}
.final-table  td{
    border-top: 1px solid #B7C72A;
}
tbody tr:hover{
    background-color: #52ACC4;
    color: #FFFFFF;
    font-weight: bold;
}
.final-table tbody tr:hover{
    background-color: #B7C72A;
} 
th:first-child, td:first-child{
    text-align: center;
}
.no-results{
    font-size: 20px;
}
</style>
